<template>
  <div>
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <FieldTitle :model="model" />
    </div>
    <div v-if="readOnly" :class="preview ? 'w-full' : 'w-3/4'">
      <div v-if="model.properties.singleSelect" class="mb-4">
        <div v-for="(item, index) in model.properties.selectable" :key="$uuid(index)" :value="item.value" disabled>
          <div v-if="item.value === singleSelected">
            <div v-html="item.text" class="w-full border bg-graylight-100 p-4 rounded" />
          </div>
        </div>
      </div>
      <div v-else class="mb-2">
        <div v-for="(item, index) in model.properties.selectable" :key="index">
          <div v-if="this.isMultiSelected(item.value)">
            <CustomCheckbox :cbxId="item.value"
                            :cbxValue="item.value"
                            :cbxDisabled="true"
                            :cbxLabel="item.text"
                            v-model="multiSelected" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="w-full pr-0 xl:w-3/4">
        <div class="relative">
          <font-awesome-icon v-show="isSaving" icon="spinner" class="animate-spin absolute right-2.5 top-3" />
        </div>
        <div v-if="model.properties.singleSelect">
          <select class="p-4 bg-transparent border-2" @change="save" v-model="singleSelected">
            <option v-for="(item, index) in model.properties.selectable" :key="$uuid(index)" :value="item.value">
              {{item.text
      }}
      </option>
      </select>
    </div>
        <div v-else>
          <div v-for="(item, index) in model.properties.selectable" :key="index">
            <CustomCheckbox
              :cbxId="item.value"
              :cbxValue="item.value"
              :cbxLabel="item.text"
              v-model="multiSelected"
              @change="save"
            />
          </div>
        </div>
      </div>
      <div class="hidden w-1/4 pr-0 mb-4 xl:block lg:pl-4">
        <CommentPane
          v-if="!model.deactivateComments"
          :model="model.comments"
          :contentGuid="model.contentGuid"
          :pageId="pageId"
          :fieldTitle="model.title"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CommentPane from '@/views/components/schema/CommentPane.vue';
  import FieldTitle from '@/views/components/schema/common/FieldTitle.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      CommentPane,
      FieldTitle,
    },
    data() {
      return {
        type: this.model.properties.selectType,
        multiSelected: this.model.properties.multiSelected === null ? [] : this.model.properties.multiSelected,
        singleSelected: this.model.properties.singleSelected,
        selectable: this.model.properties.selectable,
        isSaving: false,
      };
    },
    methods: {
      isMultiSelected(value) {
        const isSelected = this.multiSelected.filter((item) => {
          if (item === value) {
            return true;
          }
          return false;
        });
        return isSelected.length > 0;
      },
      save() {
        let selected;
        if (this.model.properties.singleSelect) {
          selected = this.singleSelected;
        } else {
          selected = this.multiSelected;
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.model.contentGuid,
            value: selected,
            type: this.type,
            pageId: this.pageId,
            relationRule: this.model.properties.relationRule,
          }),
        };
        const self = this;
        this.isSaving = true;
        fetch(`${window.location.origin}/api/schema/SaveSchemaItem`, requestOptions)
          .then(() => {
            this.$emit('onSave');
            this.$emit('getSchema');
          }).then(() => {
            self.isSaving = false;
            this.$toast.open({
              message: 'Lagret',
              type: 'success',
              dismissible: true,
            });
          });
      },
    },
  };
</script>
