<template>
  <div class="w-full pr-0">

    <!-- Kunnskapsoppsummeringer -->
    <div v-if="model.properties.knowledgeSummarySection">
      <Section :model="model.properties.knowledgeSummarySection"
               :pageId="pageId"
               :readOnly="readOnly"
               @get-schema="getSchema">
        <EvidenceTableInput :contentId="model.contentId"
                            :items="model.properties.knowledgeSummaryItems"
                            :properties="model.properties"
                            :section="KnowledgeSummary"
                            :preview="preview"
                            :readOnly="readOnly"
                            @get-schema="getSchema" />
      </Section>
    </div>

    <!-- Primærstudier -->
    <div v-if="model.properties.primaryStudySection">
      <Section :model="model.properties.primaryStudySection"
               :pageId="pageId"
               :readOnly="readOnly"
               @get-schema="getSchema">
        <EvidenceTableInput :contentId="model.contentId"
                            :items="model.properties.primaryStudyItems"
                            :properties="model.properties"
                            :section="PrimaryStudy"
                            :preview="preview"
                            :readOnly="readOnly"
                            @get-schema="getSchema" />
      </Section>
    </div>

  </div>
</template>

<script>
  import EvidenceTableInput from '@/views/components/schema/evidenceTable/EvidenceTableInput.vue';
  import Section from '@/views/components/schema/Section.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      pageId: {
        type: String,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        KnowledgeSummary: 'KnowledgeSummary',
        PrimaryStudy: 'PrimaryStudy',
      };
    },
    methods: {
      getSchema() {
        this.$emit('getSchema');
      },
    },
    components: {
      EvidenceTableInput,
      Section,
    },
    emits: ['getSchema'],
  };
</script>
