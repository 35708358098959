<template>
  <div v-if="readOnly">
    <div v-if="model.showInLeftMenu" class="flex items-center pb-3 mb-6 border-b border-gray-100" :class="preview ? 'w-full' : 'w-3/4'">
      <h2 class="font-serif font-semibold text-hmd">{{model.title}}</h2>
      <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
    </div>
    <div v-else class="flex flex-row mb-6" :class="preview ? 'w-full' : 'w-3/4'">
      <h3 class="text-xl font-medium">{{model.title}}</h3>
      <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
    </div>
  </div>
  <div v-else>
    <div v-if="model.showInLeftMenu" class="flex items-center w-full pb-3 mb-6 border-b border-gray-100 xl:w-3/4">
      <h2 class="font-serif font-semibold text-hmd">{{model.title}}</h2>
      <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
    </div>
    <div v-else class="flex flex-row w-full mb-6 xl:w-3/4">
      <h3 class="text-xl font-medium">{{model.title}}</h3>
      <Help v-if="model.helpText" v-show="model.showHelpText" :model="model.helpText" />
    </div>
  </div>
</template>

<script>
  import Help from '@/views/components/help/Help.vue';

  export default {
    props: {
      model: {
        type: Object,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      Help,
    },
  };
</script>
